.weights {
  margin: 0 1em;
}

.weights sub {
  font-size: 70%;
}

.weights .level {
  width: 5em;
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  text-align: center;
}
