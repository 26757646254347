.drawer {
  padding: 1em 1.5em;
  overflow-y: auto;
}

.drawer label {
  user-select: none;
}

.showAllToggle {
  margin-bottom: 0;
}

.inlineControls {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.inlineControls .inlineControls {
  margin-bottom: 0;
}

.inlineControls > * {
  margin-right: 25px;
}

.narrowInput {
  width: 100px;
}

/* remove native styling on number type inputs */
input[type="number"] {
  appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
}
