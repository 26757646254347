.drawings {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
}

.empty {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  justify-content: center;
}

.chartList {
  display: flex;
  padding: 10px;
  overflow: hidden;
  flex-wrap: wrap;
}

.sectionLabel {
  text-indent: 10px;
}
