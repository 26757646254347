html,
body {
  height: 100%;
  width: 100%;
}

body {
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  position: absolute;
  margin: 0;
}

body:global(.bp5-dark) {
  background-color: #222;
}

body:global(.obs-layer) {
  background-color: transparent !important;
}

:global(.bp5-dark) :global(.bp5-navbar) {
  box-shadow: none;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  width: 100%;
}

@media (min-width: 1025px) {
  .scrollable {
    overflow: auto;
    min-height: 12em;
  }
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"]
  :not(:global(.bp5-editable-text-input))
  :not(:global(.bp5-editable-text)),
input[type="time"],
input[type="url"],
input[type="week"],
select,
textarea,
button {
  font-size: 16px !important;
} /* avoid iOS auto-zoom */
